import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import Chart from './chart-1.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`PSI is a not-for-profit school governed by the Board of Governors elected from within the parent community. All School income is generated by tuition fees and is redistributed to teaching and learning activities and campus operation support. Separately, the School collects a Strategic Development Charge (formerly Capital Levy) that is accumulated in the Strategic Development Funds for the purposes of capital investments and strategic long-term initiatives.`}</p>
    <h4 {...{
      "id": "psi-enrollment-trend",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#psi-enrollment-trend",
        "aria-label": "psi enrollment trend permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PSI Enrollment Trend`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f2e8f252c59a6b15d8021c16c9eb5427/e5ca1/chart-3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAAB6klEQVQoz52R20tVQRSH/aML8qV8K6gnKyUt7CKhZgjVKRBJhBREKg8KipqmCJ59nZl9nT2z93yx99EwopcWrKcZvvX91hrxPI+B5+P7AWEUEUUxTdPgnPuvHqnrGmjQZYlzFufqv4DXq337V3fANLP0vijSLCMQNWFcYo2lMqb7VBlLnuekaUpRFH9AW0DTOOrrwFg03BiPiKOEqaWCz1teZ9zW0L7u7JVSCCEuk7jfsGECB1eRhWwYm5ZIkfF4IWX16wm7hwkbfY+y0PSPSrJMX5pZtK6w1nbDnGuwdcPZhaWq6g7aAW9PSUScMvFGs3OgeLdaMdPzCPyCm4/OCcKIJAMhq87GNXW3FmsNumy4/zJj74fqho5I2XBnWiJEyuRiyfZ+zPK6YWElIg4tY08Ficx4OJ/S3w/Z7FvWtxW+X/PsgyJRmgezGbuHcghsDUcnpGsNx+dKvu8LemuGV59CosAyOilRIuXu85j+wQWvP1bMLYecnlluTcQokXPvRc7OFVAltXvyNkaKlMWVnL3jmI1vmpXNkERZppcEWZIzv1xycp6wtqVZ3Yo7w5n3klQVzPYSDn+KIbBdri5LZ4zBmIqy1OAMx6cFg4EgkoowlijZHi6hLAoiFRBIDz8ckOUp1hi01t2VfwHqxOxtRd5GOgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart 3",
            "title": "chart 3",
            "src": "/static/f2e8f252c59a6b15d8021c16c9eb5427/c1b63/chart-3.png",
            "srcSet": ["/static/f2e8f252c59a6b15d8021c16c9eb5427/5a46d/chart-3.png 300w", "/static/f2e8f252c59a6b15d8021c16c9eb5427/0a47e/chart-3.png 600w", "/static/f2e8f252c59a6b15d8021c16c9eb5427/c1b63/chart-3.png 1200w", "/static/f2e8f252c59a6b15d8021c16c9eb5427/e5ca1/chart-3.png 1332w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h4 {...{
      "id": "the-graphs-below-illustrate-income-sources-and-expenses-categories-for-the-2020--2021-school-year",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#the-graphs-below-illustrate-income-sources-and-expenses-categories-for-the-2020--2021-school-year",
        "aria-label": "the graphs below illustrate income sources and expenses categories for the 2020  2021 school year permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The graphs below illustrate Income sources and Expenses categories for the 2020 – 2021 school year.`}</h4>
    <Chart mdxType="Chart" />
    <p>{`The graphs above are generally typical for not for profit international schools.`}</p>
    <p>{`Staff and their expertise are seen as the major expense and greatest asset of the school.`}</p>
    <p>{`PSI continues to increase the reserve fund to strengthen PSI financial and operational stability. According to PSI policies, it amounts to 25% of Operational Expenses. The reserve fund reached 35% of total expenses in SY 2020 – 2021, reflecting the School's strong position in regards to the ongoing economic shortfalls due to COVID-19.`}</p>
    <p>{`The Strategic Development Fund aims to ensure the School has funds for its development and strategic initiatives to improve the learning process.`}</p>
    <p>{`The financial reports of the School were confirmed by Deloitte this year.`}</p>
    <p>{`The Auditor’s report showed an unqualified opinion on PSI financial results and provided a couple of recommendations for ensuring the financial reports match entirely to IFRS standards.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      